
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */



import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import jquery from 'jquery'
window.$ = jquery;

import { replaceSpecialChars } from './replaceSpecialChars';

// 商品管理番号チェック32文字以内の半角英数字とハイフンのみ許可
window.isManagementNo = function (str) {
    var re = /^[0-9a-zA-Z\-]{1,32}$/;
    return re.test(str);
}
$(function () {

    $('[data-toggle="tooltip"]').tooltip()


    $('tbody tr[data-href]').addClass('clickable').click(function () {
        let href = $(this).data('href');
        let target = $(this).data('target');
        target = (undefined == target) ? '' : target;

        if (target) {
            window.open(href, target);
        } else {
            window.location = href;
        }
    }).find('a,.nonclickable').click(function (event) {
        event.stopPropagation();

    }).find('a').hover(function () {


        $(this).parents('tr').unbind('click');
    }, function () {
        $(this).parents('tr').click(function () {
            event.stopPropagation();
            window.location = $(this).data('href');
        });
    });;

    // 全角1文字半角0.5文字としてカウントする処理
    // data-maxlengthには全角の上限文字数を入力
    $('[data-counter]').keyup(function () {
        let text = '';;
        let target = $(this).data('counter');

        if (target) {
            let str = $(this).val();
            let maxlength = $(this).data('maxlength');
            let count = 0;

            for (let i = 0, len = str.length; i < len; i++) {
                let c = str.charCodeAt(i)

                if (!str[i].match(/\r?\n/g)) { // 改行コード判定
                    if (c >= 0x0 && c <= 0x7f) { // 全角半角判定
                        count += 0.5
                    } else {
                        count += 1
                    }
                }
            }
            text = count + '文字';
            if (maxlength) {
                text += ' / ' + maxlength + '文字';
            }
            $(target).text(text);
        }
        return false;
    });
    $('[data-counter]').trigger('keyup');




    $('[data-only-numbers]').on("input", function (event) {
        let value = $(this).val();
        if (!value.match(/^[0-9]*$/)) {
            $(this).val(value.replace(/[^\d]/g, ""));
        }
    });


    // 自動変換
    $('[data-auto-convert]').on('blur', function () {

        let mode = $(this).data('auto-convert');
        let text = $(this).val();


        switch (mode) {
            case 'z2h':
                // 全角→半角
                text = text.replace(/[Ａ-Ｚａ-ｚ０-９＋－＝（）＊＆＾％＄＃＠！？／]/g, function (s) {
                    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
                });
                text = text.replace(/ー/g, '-');
                text = text.replace(/。/g, '.');

                $(this).val(text);

                break;
            case 'h2z':
                // 半角→全角
                text = text.replace(/[A-Za-z0-9+-=()*&^%$#@!?/]/g, function (s) {
                    return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
                });
                text = text.replace(/-/g, 'ー');
                text = text.replace(/\./g, '。');
                $(this).val(text);
                break;
        }



    });

    // オプション禁止文字チェック
    $('[data-forbidden-chars]').on('blur', function () {
        return validateInput($(this));
    });

    function validateInput($input) {
        let result = true;

        // 禁止文字を置換
        $input.val(replaceSpecialChars($input.val()));
        const userInput = $input.val();

        // 禁止されている文字をデータ属性から取得
        var forbiddenChars = $input.data('forbidden-chars').split('');

        // 禁止されている文字が入力に含まれているかチェック
        $.each(forbiddenChars, function (index, char) {
            $input.removeClass('is-invalid');
            if (userInput.includes(char)) {
                $input.siblings('.invalid-feedback').text('使用できない文字「' + char + '」が入力されています。');
                result = false;
                return false; // $.each のループを抜ける
            }
        });
        if (result) {
            $input.siblings('.invalid-feedback').text('');
            $input.removeClass('is-invalid');
        } else {
            $input.addClass('is-invalid');
        }
        return result;
    }
});