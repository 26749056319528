/**
 * 機種依存文字を代替文字に置換する
 * @param {string} text - 置換対象のテキスト
 * @returns {string} 置換後のテキスト
 */
export const replaceSpecialChars = text => {

    if (!text) return text;

    const replacements = {
        '①': '(1)',
        '②': '(2)',
        '③': '(3)',
        '④': '(4)',
        '⑤': '(5)',
        '⑥': '(6)',
        '⑦': '(7)',
        '⑧': '(8)',
        '⑨.': '(9)',
        '⑩': '(10)',
        '㈱': '(株)',
        '㈲': '(有)',
        '℡': 'TEL',
        '㎜': 'mm',
        '㎝': 'cm',
        '㎞': 'km',
        '㎎': 'mg',
        '㎏': 'kg',
        '㏄': 'cc',
        // ローマ数字は正しい置換規則を定義
        'Ⅰ': 'I',
        'Ⅱ': 'II',
        'Ⅲ': 'III',
        'Ⅳ': 'IV',
        'Ⅴ': 'V',
        'Ⅵ': 'VI',
        'Ⅶ': 'VII',
        'Ⅷ': 'VIII',
        'Ⅸ': 'IX',
        'Ⅹ': 'X',
        'Ⅺ': 'XI',
        'Ⅻ': 'XII',
        // その他の機種依存文字も必要に応じて追加
        '㍊': 'ミリバール',
        '㍋': 'キロバール',
        '㌃': 'アール',
        '㌦': 'ドル',
        '㌍': 'カロリー',
        '㌫': 'パーセント',
        '㌰': 'ヘルツ',
        '㌳': 'ワット',
        '㌻': 'ボルト',
        '㌾': 'オーム',
        '㏀': 'キロ',
        '㏐': 'ジュール',
        '㏔': 'メガ',
        '㏕': 'マイクロ',
        '㏖': 'ピコ',
        '㏗': 'フェムト',
        '㏘': 'アト',
        '㏙': 'ゼプト',
        '㏚': 'ヨクト',
    };

    // 置換対象の文字をキーとして持つ正規表現を作成
    const regex = new RegExp(Object.keys(replacements).join('|'), 'g');

    return text.replace(regex, match => replacements[match] || match);
}